<template>
  <div class="timeline">
    <LoadingSpinner v-if="loadingtimeline" />
    <template v-else>
      <PostCard 
        v-for="post in posts" 
        :key="post.id" 
        :post="post"
        :ref="(el) => { if (el) postCards[post.id] = el }"
        @tips-count-updated="(count) => updateTipsCount(post.id, count)"
        @like-updated="(likeData) => updateLike(post.id, likeData)"
        @post-deleted="handlePostDeleted"
      />
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import PostCard from '@/components/PostCard.vue';

export default {
  name: 'HomeTimeline',
  components: {
    LoadingSpinner,
    PostCard
  },
  data() {
    return {
      posts: [],
      userID: null,
      offset: 0,
      limit: 5,
      loading: false,
      loadingtimeline: true,
      postCards: {},
    };
  },
  metaInfo: {
    title: 'Home Timeline',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' }
    ]
  },
  created() {
    this.fetchPosts();
    this.setupScrollListener();
  },
  methods: {
    async fetchPosts() {
      if (this.loading || this.posts.length >= 1000) return;
      this.loading = true;

      try {
        const response = await axios.get('https://api.6buoys.com/api/post-list/', {
          params: {
            limit: this.limit,
            offset: this.offset,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });

        const newPosts = response.data.map(post => ({
          ...post,
          showTips: false,
          tips: [],
          tips_count: post.tips_count || 0,
          likes_count: post.likes_count || 0,
          liked_by_user: post.liked_by_user || false,
          like_id: post.like_id || null,
          author: post.author || {
            id: null,
            username: '',
            avatar: 'default-avatar.png',
            first_name: 'Unknown',
            last_name: ''
          }
        }));

        this.posts = [...this.posts, ...newPosts];
        this.offset += this.limit;

        // Trigger preloading of tips for all new posts
        this.$nextTick(() => {
          newPosts.forEach(post => {
            const postCard = this.postCards[post.id];
            if (postCard && postCard.preloadTips) {
              postCard.preloadTips();
            }
          });
        });

      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        this.loading = false;
        this.loadingtimeline = false;
      }
    },

    setupScrollListener() {
      window.addEventListener('scroll', () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 && !this.loading) {
          this.fetchPosts();
        }
      });
    },

    updateTipsCount(postId, count) {
      const post = this.posts.find(p => p.id === postId);
      if (post) {
        post.tips_count = count;
      }
    },

    updateLike(postId, likeData) {
      const post = this.posts.find(p => p.id === postId);
      if (post) {
        post.liked_by_user = likeData.liked_by_user;
        post.likes_count = likeData.likes_count;
        post.like_id = likeData.like_id;
      }
    },

    handlePostDeleted(postId) {
      // Remove the post from the posts array
      this.posts = this.posts.filter(post => post.id !== postId);
      // Clean up the reference
      delete this.postCards[postId];
    }
  },
};
</script>

<style scoped>
/* General Styles */
body {
  background-color: #15202b;
  color: #d9d9d9;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.timeline {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 0px 56px 0px;
  background-color: #15202b;
}

@media screen and (max-width: 600px) {
  .timeline {
    padding: 20px 4px 56px 4px;
  }
}

</style>
