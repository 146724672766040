<template>
    <div class="create-post">
      <img src="@/assets/logo.png" alt="Logo" class="post-logo">
      <h2>Create a New Post</h2>
      <form @submit.prevent="submitPost" class="create-post-form">
        <div class="form-group">
          <textarea v-model="postData.content" placeholder="Tell us about your ski session..." required></textarea>
        </div>
        <div class="form-group">
          <select v-model="postData.speed" class="select-field">
            <option disabled value="">Select Speed</option>
            <option v-for="(label, value) in speedOptions" :key="value" :value="value">{{ label }}</option>
          </select>
        </div>
        <div class="form-group">
          <select v-model="postData.rope" class="select-field">
            <option disabled value="">Select Rope Length</option>
            <option v-for="(label, value) in ropeOptions" :key="value" :value="value">{{ label }}</option>
          </select>
        </div>
        <div class="form-group">
          <input type="file" @change="onFileChange('video', $event)" accept="video/mp4,video/quicktime,video/x-m4v" class="file-input" required>
        </div>
        <button type="submit" class="submit-button">Post</button>
        <div v-if="progress" class="progress-container">
          <label :for="'progress-' + _uid" style="margin-right: 8px; color: #1da1f2;">
            {{ progress }}%
          </label>
          <progress 
            :id="'progress-' + _uid"
            :value="progress" 
            max="100" 
            class="progress-bar" 
            style="width: 100%; margin-top: 10px; height: 10px; border-radius: 10px !important; background-color: #38444d !important; color: #1da1f2 !important;"
            :aria-valuenow="progress" 
            aria-valuemin="0" 
            aria-valuemax="100"
          >
            {{ progress }}%
          </progress>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        postData: {
          content: '',
          speed: '58',  // Default to 58kph - 36mph
          rope: '18',   // Default to 18.25 meter - 15 Off
          video: null,
        },
        progress: null,
        speedOptions: {
          '58': '58kph - 36mph',
          '55': '55kph - 34mph',
          '52': '52kph - 32mph',
          '49': '49kph - 30mph',
          '46': '46kph - 28mph',
          '43': '43kph - 26mph',
          '40': '40kph - 25mph',
          '37': '37kph - 23mph',
        },
        ropeOptions: {
          '18': '18.25 meter - 15 Off',
          '16': '16.00 meter - 22 Off',
          '14': '14.25 meter - 28 Off',
          '13': '13.00 meter - 32 Off',
          '12': '12.00 meter - 35 Off',
          '11': '11.25 meter - 38 Off',
          '10.75': '10.75 meter - 39.5 Off',
          '10.25': '10.25 meter - 41 Off',
          '9': '9.75 meter - 43 Off',
        },
      };
    },
    metaInfo: {
    title: 'Create New Post',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' }
    ]
  },
    methods: {
      onFileChange(type, event) {
        if (type === 'video') {
          const file = event.target.files[0];
          const MAX_FILE_SIZE = 300 * 1024 * 1024; // 300MB in bytes

          if (file.size > MAX_FILE_SIZE) {
            alert("File is too large. Please upload a video smaller than 300MB.");
            this.postData.video = null;
            event.target.value = '';
            return;
          }

          const videoElement = document.createElement('video');
          videoElement.src = URL.createObjectURL(file);
          videoElement.preload = 'metadata';

          videoElement.onloadedmetadata = () => {
            URL.revokeObjectURL(videoElement.src);
            const duration = videoElement.duration;

            if (duration > 600) { 
              alert("Please upload a video shorter than 1 minute.");
              this.postData.video = null;
              event.target.value = '';
            } else {
              this.postData.video = file;
            }
          };
        }
      },
      async submitPost() {
        if (!this.postData.video) {
          alert("Please upload a video file.");
          return;
        }

        try {
          const token = localStorage.getItem('access_token');
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

          // First upload the video in chunks
          const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunks
          const file = this.postData.video;
          const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
          let uploadId = null;

          for (let chunkNumber = 1; chunkNumber <= totalChunks; chunkNumber++) {
            const start = (chunkNumber - 1) * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, file.size);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append('chunk', chunk);
            formData.append('chunk_number', chunkNumber);
            formData.append('total_chunks', totalChunks);
            formData.append('filename', file.name);
            if (uploadId) {
              formData.append('upload_id', uploadId);
            }

            const response = await axios.post('https://api.6buoys.com/api/upload-chunk/', formData, {
              onUploadProgress: (progressEvent) => {
                // Calculate overall progress considering current chunk
                const chunkProgress = (progressEvent.loaded / progressEvent.total) * 100;
                this.progress = Math.round(((chunkNumber - 1) * 100 + chunkProgress) / totalChunks);
              },
            });

            if (response.data.upload_id) {
              uploadId = response.data.upload_id;
            }

            if (response.data.status === 'complete') {
              // Once video is uploaded, create the post
              const postResponse = await axios.post('https://api.6buoys.com/api/create-post-with-video/', {
                content: this.postData.content,
                speed: this.postData.speed,
                rope: this.postData.rope,
                video_path: response.data.video_path
              });

              console.log('Post created:', postResponse.data);
              this.$router.push('/timeline');
              return;
            }
          }
        } catch (error) {
          console.error('Failed to create post:', error.response);
          alert('Failed to upload video or create post. Please try again.');
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .create-post {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #192734;
    border-radius: 16px;
    color: #d9d9d9;
  }
  
  .post-logo {
    display: block;
    margin: 0 auto 5px;
    width: 80px; 
  }
  
  .create-post h2 {
    text-align: center;
    color: #1da1f2;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  
  .create-post-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    padding: 10px;
    background-color: #15202b;
    border: 1px solid #38444d;
    border-radius: 10px;
    color: #ffffff;
    font-size: 1em;
    resize: none;
    min-height: 50px;
    font-weight: bold;
  }
  
  textarea::placeholder {
    color: #8899a6;
  }
  
  .select-field {
    font-size: 1rem;
    padding: 14px;
    background-color: #15202b;
    border: 1px solid #38444d;
    border-radius: 10px;
    color: #ffffff;
    font-weight: bold;
  }
  
  .file-input {
    padding: 10px;
    background-color: #15202b;
    border: 1px solid #38444d;
    border-radius: 10px;
    color: #ffffff;
  }
  
  .submit-button {
    padding: 12px 20px;
    background-color: #1da1f2;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0c8ddb;
  }
  
  .progress-bar{
    width: 100%;
    margin-top: 10px;
    height: 10px;
    border-radius: 10px !important;
    background-color: #38444d;
    color: #1da1f2 !important;
  }
  </style>
  