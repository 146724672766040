<template>
  <div v-if="post" class="post">

    <!-- Post Header and Details -->
    <div class="post-header">
      <a :href="`/${postAuthor.username}`">
        <img :src="postAuthor.avatar" alt="Author Profile" class="profile-pic" />
      </a>
      <div class="post-details">
        <p class="author">
          {{ postAuthor.first_name }}
          <img
            v-if="postAuthor.verified"
            src="@/assets/verified.png"
            alt="Verified"
            class="verified-badge"
            title="Verified Skier"
          />
        </p>
        <p class="post-date">{{ formatDate(postCreated) }}</p>
      </div>
      <div class="post-info">
        <span v-if="post.rope" class="info-badge">{{ post.rope }} meter</span>
        <span v-if="post.speed" class="info-badge">{{ post.speed }} kmph</span>
        <div class="menu-container">
          <span class="three-dots" @click="toggleDropdown(post.id)">&#x22EE;</span>
          <div class="dropdown-menu" v-if="isDropdownOpen(post.id)">
            <ul>
              <li @click="reportContent(post.id)">Report content</li>
              <li @click="blockUser(post.author?.id)">Block user</li>
              <li v-if="isOwnPost" @click="deletePost(post.id)">Delete post</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Post Content -->
    <div class="post-content">
      <img v-if="post.image" :src="post.image" alt="Post image" class="post-image" />
      <div 
        v-if="post.video"
        class="video-container"
        @click="handleVideoClick"
      >
        <video
          ref="video"
          :src="post.video"
          muted
          autoplay
          loop
          playsinline
          class="post-video"
        ></video>
        <div v-if="isVideoPaused" class="play-button-overlay">
          <i class="fas fa-play"></i>
        </div>
        <div class="fullscreen-button" @click.stop="toggleFullscreen">
          <i :class="isFullscreen ? 'fas fa-compress' : 'fas fa-expand'"></i>
        </div>
      </div>
      <p class="post-text">{{ postContent }}</p>
    </div>
    <div id="loading" style="display: none;">Loading...</div>
    <!-- Post Actions -->
    <div class="post-actions">
      <div class="tips-section" @click="toggleTips">
        <i class="fas fa-comments"></i>
        <span v-if="localTipsCount !== undefined">{{ localTipsCount }} Waterski Tips</span>
      </div>
      <div class="likes-section" @click="handleLike">
        <i :class="localLikedByUser ? 'fas fa-heart liked' : 'far fa-heart not-liked'"></i>
        <span v-if="localLikesCount !== undefined">{{ localLikesCount }} Likes</span>
      </div>
    </div>
    
    <!-- Waterski Tips -->
    <WaterskiTips
      v-if="showTips && post.id"
      :post-id="post.id"
      :initial-tips="preloadedTips"
      :show-new-tip-form="true"
      @update:tips-count="updateTipsCount"
    />
  </div>
</template>

<script>
import axios from 'axios';
import WaterskiTips from '@/components/WaterskiTips.vue';
import { ref } from 'vue';
import { Capacitor } from '@capacitor/core';
import { ScreenOrientation } from '@capacitor/screen-orientation';

export default {
  name: 'PostCard',
  components: {
    WaterskiTips
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    publicMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingTips: {},
      newTip: {},
      newReply: {},
      userVotes: {},
      dropdownStates: {},
      userID: null,
      videoStates: ref({}),
      lastTap: 0,
      pinchStartDistance: null,
      showTips: false,
      tips: [],
      localLikedByUser: false,
      localLikesCount: 0,
      localLikeId: null,
      localTipsCount: 0,
      preloadedTips: [],
      pausedVideos: new Set(),
      isVideoPaused: false,
      isFullscreen: false,
      isNative: Capacitor.isNativePlatform(),
    };
  },
  computed: {
    // Add computed properties to safely access post data
    postAuthor() {
      return this.post?.author || {
        username: '',
        avatar: 'default-avatar.png',
        first_name: 'Unknown',
        last_name: ''
      };
    },
    postContent() {
      return this.post?.content || '';
    },
    postCreated() {
      return this.post?.created || new Date();
    },
    postTips() {
      return this.tips;
    },
    postTipsCount() {
      return this.post?.tips_count || 0;
    },
    postLikesCount() {
      return this.post?.likes_count || 0;
    },
    postLikedByUser() {
      return this.post?.liked_by_user || false;
    },
    isOwnPost() {
      return this.userID === this.post?.author?.id;
    }
  },
  metaInfo: {
    title: 'Post Card',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' }
    ]
  },
  mounted() {
    this.getUserID();
    this.preloadTips();
    document.addEventListener('click', this.handleClickOutside);
  },
  watch: {
    post: {
      immediate: true,
      handler(newPost) {
        if (newPost) {
          this.localLikedByUser = newPost.liked_by_user;
          this.localLikesCount = newPost.likes_count;
          this.localLikeId = newPost.like_id;
          this.localTipsCount = newPost.tips_count;
        }
      }
    }
  },
  methods: {
    handleVideoClick() {
      console.log('Video clicked');
      const videoElement = this.$refs.video;
      console.log('Video element:', videoElement);
      console.log('Is paused:', videoElement?.paused);
      
      if (videoElement) {
        if (videoElement.paused) {
          console.log('Attempting to play video');
          videoElement.play()
            .then(() => {
              console.log('Video started playing');
              this.isVideoPaused = false;
            })
            .catch(error => console.error('Error playing video:', error));
        } else {
          console.log('Attempting to pause video');
          videoElement.pause();
          this.isVideoPaused = true;
          console.log('Video paused');
        }
      }
    },
    async fetchComments(postId) {
      this.loadingTips[postId] = true;
      try {
        const response = await axios.get(`https://api.6buoys.com/api/post-comments/${postId}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });

        this.tips = await Promise.all(response.data.map(async tip => ({
          ...tip,
          userVote: this.userVotes[tip.id]?.rate || 0,
          replies: [],
          reply_count: await this.fetchReplyCount(tip.id),
          showReplies: false,
        })));
      } catch (error) {
        console.error('Error fetching tips:', error);
      } finally {
        this.loadingTips[postId] = false;
      }
    },
    async createTip(postId) {
      if (!this.newTip[postId] || !this.newTip[postId].trim()) return;

      try {
        const token = localStorage.getItem('access_token');
        await axios.post(
          'https://api.6buoys.com/api/comment-create/',
          { post: postId, body: this.newTip[postId] },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.newTip[postId] = '';
        this.fetchComments(postId);
      } catch (error) {
        console.error('Error creating tip:', error);
      }
    },
    async handleLike() {
      if (this.publicMode) {
        this.$emit('click:like');
        return;
      }
      const token = localStorage.getItem('access_token');

      // Optimistically update the UI using local data
      this.localLikedByUser = !this.localLikedByUser;
      this.localLikesCount += this.localLikedByUser ? 1 : -1;

      try {
        if (!this.localLikedByUser) {
          await axios.delete(`https://api.6buoys.com/api/like-delete/${this.localLikeId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.localLikeId = null;
        } else {
          const response = await axios.post(
            'https://api.6buoys.com/api/like-create/',
            { post: this.post.id, user: this.userID, value: 'like' },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          this.localLikeId = response.data.id;
        }
        
        // Instead of modifying the post prop directly, emit an event
        this.$emit('like-updated', {
          liked_by_user: this.localLikedByUser,
          likes_count: this.localLikesCount,
          like_id: this.localLikeId
        });
      } catch (error) {
        // Revert the optimistic update if there's an error
        this.localLikedByUser = !this.localLikedByUser;
        this.localLikesCount += this.localLikedByUser ? 1 : -1;
        console.error('Error handling like:', error.response ? error.response.data : error.message);
      }
    },
    async handleVote(tip, value) {
      if (this.publicMode) {
        this.$emit('click:rate');
        return;
      }
      const currentVote = tip.userVote || 0;

      try {
        const token = localStorage.getItem('access_token');
        const rateId = this.userVotes[tip.id]?.rateId;

        if (currentVote !== 0 && currentVote !== value) {
          await axios.delete(`https://api.6buoys.com/api/rate-delete/${rateId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.updateTipVotes(tip, currentVote, 0);
        }

        if (currentVote !== value) {
          const requestData = { comment: tip.id, rate: value, user: this.userID };
          let response;

          if (rateId && currentVote !== 0) {
            response = await axios.post(
              `https://api.6buoys.com/api/rate-update/${rateId}/`,
              requestData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            response = await axios.post(
              'https://api.6buoys.com/api/rate-create/',
              requestData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }

          this.userVotes[tip.id] = {
            rateId: response.data.id,
            rate: value,
          };
          this.updateTipVotes(tip, 0, value);
        } else if (currentVote === value) {
          await axios.delete(`https://api.6buoys.com/api/rate-delete/${rateId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.updateTipVotes(tip, currentVote, 0);
        }
      } catch (error) {
        console.error('Error handling vote:', error.response ? error.response.data : error.message);
      }
    },
    updateTipVotes(tip, currentVote, newVote) {
      if (newVote === 0) {
        tip.ratecount -= currentVote;
        tip.userVote = 0;
        delete this.userVotes[tip.id];
      } else {
        tip.ratecount += newVote - currentVote;
        tip.userVote = newVote;
        this.userVotes[tip.id] = { ...this.userVotes[tip.id], rate: newVote };
      }
    },
    getUserVote(tipId) {
      return this.userVotes[tipId]?.rate || 0;
    },
    async getUserID() {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('https://api.6buoys.com/api/profile/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.userID = response.data.id; // Ensure this line sets the correct user ID
        this.fetchUserVotes();
      } catch (error) {
        console.error('Error fetching user ID:', error);
      }
    },
    async fetchUserVotes() {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('https://api.6buoys.com/api/user-comment-rates/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.userVotes = response.data.reduce((acc, vote) => {
          acc[vote.comment] = { rate: vote.rate, rateId: vote.id };
          return acc;
        }, {});
      } catch (error) {
        console.error('Error fetching user votes:', error);
      }
    },
    toggleTips() {
      if (this.publicMode) {
        this.$emit('click:tips');
        return;
      }
      this.showTips = !this.showTips;
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    async confirmDelete(commentId) {
      if (confirm('Are you sure you want to delete this comment?')) {
        try {
          const token = localStorage.getItem('access_token');
          await axios.delete(`https://api.6buoys.com/api/comment-delete/${commentId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.tips = this.tips.filter(tip => tip.id !== commentId);
          this.$emit('update:tips-count', (this.post.tips_count || 0) - 1);
        } catch (error) {
          console.error('Error deleting comment:', error);
        }
      }
    },
    async confirmReplyDelete(replyId) {
      if (confirm('Are you sure you want to delete this reply?')) {
        try {
          const token = localStorage.getItem('access_token');
          await axios.delete(`https://api.6buoys.com/api/reply-delete/${replyId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.removeReplyFromTip(replyId);
          
        } catch (error) {
          console.error('Error deleting reply:', error);
        }
      }
    },
    removeReplyFromTip(replyId) {
      this.tips.forEach(tip => {
        if (tip.replies) {
          tip.replies = tip.replies.filter(reply => reply.id !== replyId);
          // Update reply count
          tip.reply_count = tip.replies.length;
        }
      });
    },
    async fetchReplyCount(tipId) {
      try {
        const response = await axios.get(`https://api.6buoys.com/api/comment-reply-count/${tipId}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });
        return response.data.count;
      } catch (error) {
        console.error('Error fetching reply count:', error);
        return 0;
      }
    },
    async createReply(tipId) {
      if (this.publicMode) {
        this.$emit('click:reply');
        return;
      }
      if (!this.newReply[tipId] || !this.newReply[tipId].trim()) return;

      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.post(
          'https://api.6buoys.com/api/reply-create/',
          {
            comment: tipId,
            body: this.newReply[tipId],
            user: this.userID,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.id) {
          this.newReply[tipId] = ''; // Clear the input field

          // Find the tip and update its replies
          const tip = this.tips.find(t => t.id === tipId);
          if (tip) {
            if (!tip.replies) tip.replies = [];
            tip.replies.push(response.data);
            tip.reply_count = (tip.reply_count || 0) + 1;
          }
        }
      } catch (error) {
        console.error('Error creating reply:', error);
      }
    },
    async toggleReplies(tipId) {
      // Find the tip in our local tips array
      const tip = this.tips.find(t => t.id === tipId);
      if (!tip) return;

      tip.showReplies = !tip.showReplies;

      if (tip.showReplies && (!tip.replies || tip.replies.length === 0)) {
        try {
          const response = await axios.get(
            `https://api.6buoys.com/api/comment-replies/${tipId}/`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              },
            }
          );
          tip.replies = response.data;
        } catch (error) {
          console.error('Error fetching replies:', error);
          tip.replies = [];
        }
      }
    },
    toggleDropdown(postId, event) {
      if (this.publicMode) {
        this.$emit('click:menu');
        return;
      }
      
      if (event) {
        event.stopPropagation();
      }
      this.dropdownStates[postId] = !this.dropdownStates[postId];
    },
    isDropdownOpen(postId) {
      return this.dropdownStates[postId] || false;
    },
    async reportContent(postId) {
      if (this.publicMode) {
        this.$emit('click:menu');
        return;
      }
      
      this.dropdownStates[postId] = false;

      try {
        const token = localStorage.getItem('access_token');
        const userId = this.userID; 

        if (!token) {
          alert('You need to be logged in to report content.');
          return;
        }

        if (!userId) {
          console.error('User ID not found!');
          return;
        }

        const response = await axios.post(
          'https://api.6buoys.com/api/report-post/', 
          { 
            post: postId,  
            user: userId   
          },  
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log('API Response:', response.data);

        alert(`Reported content for post ID: ${postId}. Thank you for your feedback!`);
      } catch (error) {
        console.error('Error reporting post:', error.response ? error.response.data : error.message);
        alert('There was an error reporting the post. Please try again later.');
      }
    },
    async blockUser(postId) {
      if (this.publicMode) {
        this.$emit('click:menu');
        return;
      }
      
      this.dropdownStates[postId] = false;

      try {
        const token = localStorage.getItem('access_token');
        const userId = this.userID; 

        if (!token) {
          alert('You need to be logged in to block a user.');
          return;
        }

        if (!userId) {
          console.error('User ID not found!');
          return;
        }

        const response = await axios.post(
          'https://api.6buoys.com/api/report-post/', 
          { 
            post: postId,  
            user: userId   
          },  
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log('API Response:', response.data);

        alert(`You succesfully blocked this user. Thank you for your feedback!`);
      } catch (error) {
        console.error('Error blocking this user:', error.response ? error.response.data : error.message);
        alert('There was an error when blocking this user. Please try again later.');
      }
    },
    async preloadTips() {
      try {
        // First, ensure we have the user votes
        if (Object.keys(this.userVotes).length === 0) {
          await this.fetchUserVotes();
        }

        const response = await axios.get(`https://api.6buoys.com/api/post-comments/${this.post.id}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });

        this.preloadedTips = await Promise.all(response.data.map(async tip => ({
          ...tip,
          userVote: this.userVotes[tip.id]?.rate || 0,  // This sets the user's vote
          replies: [],
          reply_count: await this.fetchReplyCount(tip.id),
          showReplies: false,
        })));
      } catch (error) {
        console.error('Error preloading tips:', error);
      }
    },
    updateTipsCount() {
      if (!this.post || !this.post.id) return;
      
      axios.get(`https://api.6buoys.com/api/post/${this.post.id}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then(response => {
        this.localTipsCount = response.data.tips_count;
        this.$emit('tips-count-updated', response.data.tips_count);
      }).catch(error => {
        console.error('Error updating tips count:', error);
      });
    },
    async toggleFullscreen(event) {
      event.stopPropagation(); // Prevent video play/pause
      
      if (this.isNative) {
        try {
          if (!this.isFullscreen) {
            // Enter fullscreen (landscape)
            await ScreenOrientation.lock({
              orientation: 'landscape'
            });
            
            // Add fullscreen class to video container
            event.target.closest('.video-container').classList.add('mobile-fullscreen');
            this.isFullscreen = true;
          } else {
            // Exit fullscreen (back to portrait)
            await ScreenOrientation.unlock();
            await ScreenOrientation.lock({
              orientation: 'portrait'
            });
            
            // Remove fullscreen class
            event.target.closest('.video-container').classList.remove('mobile-fullscreen');
            this.isFullscreen = false;
          }
        } catch (error) {
          console.error('Error toggling fullscreen:', error);
        }
      } else {
        // Use existing web fullscreen code
        const videoContainer = event.target.closest('.video-container');
        
        if (!this.isFullscreen) {
          try {
            if (videoContainer.requestFullscreen) {
              await videoContainer.requestFullscreen();
            } else if (videoContainer.webkitRequestFullscreen) {
              await videoContainer.webkitRequestFullscreen();
            }
            this.isFullscreen = true;
          } catch (error) {
            console.error('Error entering fullscreen:', error);
          }
        } else {
          try {
            if (document.exitFullscreen) {
              await document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
              await document.webkitExitFullscreen();
            }
            this.isFullscreen = false;
          } catch (error) {
            console.error('Error exiting fullscreen:', error);
          }
        }
      }
    },
    async deletePost(postId) {
      if (confirm('Are you sure you want to delete this post?')) {
        try {
          const token = localStorage.getItem('access_token');
          await axios.delete(`https://api.6buoys.com/api/post-delete/${postId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          // Hide the post immediately by setting post to null
          this.$emit('update:post', null);
          
          // Also emit post-deleted event for any parent components that need it
          this.$emit('post-deleted', postId);
          
          // Close the dropdown
          this.dropdownStates[postId] = false;
        } catch (error) {
          console.error('Error deleting post:', error);
          alert('There was an error deleting the post. Please try again later.');
        }
      }
    },
    handleClickOutside(event) {
      const dropdowns = document.querySelectorAll('.dropdown-menu');
      const threeDots = document.querySelectorAll('.three-dots');
      
      let clickedOutside = true;
      
      [...dropdowns, ...threeDots].forEach(element => {
        if (element.contains(event.target)) {
          clickedOutside = false;
        }
      });

      if (clickedOutside) {
        this.dropdownStates = {};
      }
    },
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    if (this.isNative && this.isFullscreen) {
      ScreenOrientation.unlock();
      ScreenOrientation.lock({
        orientation: 'portrait'
      });
    }
  },
};
</script>

<style scoped>
/* General Styles */
body {
  background-color: hsl(210, 34%, 13%);
  color: #d9d9d9;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}


.post {
  border-radius: 16px;
  margin-bottom: 20px;
  background-color: #192734;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.post:hover {
  background-color: #22303c;
}

.post-header {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 12px 16px;
}

.post-header a {
  display: flex;
  align-items: center;
}

.profile-pic {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
  border: 2px solid #1da1f2;
  flex-shrink: 0;
}

.post-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.author {
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  font-size: 1em;
  display: flex;
  align-items: center;
  gap: 5px;
}

.verified-badge {
  width: 18px;
  height: 18px;
}

.post-date {
  font-size: 0.85em;
  color: #8899a6;
  margin: 0;
}

.post-info {
  display: flex;
  gap: 8px;
  align-items: center;
}

.info-badge {
  display: inline-block;
  color: #ffffff;
  padding: 4px 10px;
  border: #38444d 1px solid;
  border-radius: 20px;
  font-size: 0.85em;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
}

.post-content {
  padding: 0px 16px;
}

@media screen and (max-width: 600px) {
  .post-content {
    padding: 0px 10px;
  }
}

.post-image {
  width: 100%;
  border-radius: 16px;
  max-height: 400px;
  object-fit: cover;
  margin-bottom: 12px;
}

.post-video {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 12px;
}

.post-text {
  font-size: 1em;
  color: #ffffff;
  text-align: left;
  margin: 0;
}

.post-actions {
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  color: #8899a6;
  font-size: 1em;
}

.menu-container {
  position: relative;
  display: inline-block;
}

.three-dots {
  cursor: pointer;
  font-size: 18px;
  color: #8899a6; /* Match the color with the rest of the theme */
}

.dropdown-menu {
  display: block; /* Ensure the dropdown shows up */
  position: absolute;
  right: 0;
  background-color: #192734; /* Match the dark background of the app */
  border: 1px solid #38444d; /* Border style to match the app theme */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Slight shadow for depth */
  z-index: 10;
  width: 160px; /* Increase the width for more space */
  border-radius: 8px; /* Round the corners */
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  padding: 10px 15px; /* Adjust padding for better spacing */
  cursor: pointer;
  color: #e1e8ed; /* Light text color for readability */
}

.dropdown-menu ul li:hover {
  background-color: #22303c; /* Slightly lighter dark color on hover */
}

.tips-section {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
}

.tips-section:hover {
  color: #8899a6;
}

.likes-section {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: white;
}

.likes-section i {
  transition: color 0.2s;
}

.likes-section i.liked {
  color: #e0245e;
}

.likes-section i.not-liked {
  color: white;
  border: none;
}

.likes-section i:hover {
  color: #ff5a78;
  transform: scale(1.2);
}

.video-container {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.post-video {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 12px;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.56);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.play-button-overlay i {
  pointer-events: none;
}

.loading-tips {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.fullscreen-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
}

.fullscreen-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.video-container:fullscreen .post-video {
  width: 100vw;
  height: 100vh;
  margin: 0;
  object-fit: contain;
  background: black;
}

.video-container:fullscreen {
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add these new styles for mobile fullscreen */
.video-container.mobile-fullscreen {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 9999 !important;
  background: black !important;
  margin: 0 !important;
  padding: 0 !important;
}

.video-container.mobile-fullscreen .post-video {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
  margin: 0 !important;
  border-radius: 0 !important;
}

.video-container.mobile-fullscreen .fullscreen-button {
  top: 20px;
  right: 20px;
}
</style>
