<template>
  <div class="timeline">
    <LoadingSpinner v-if="loading" />
    <template v-else>
      <!-- Use PostCard component for each post -->
      <PostCard
        v-for="post in posts" 
        :key="post.id" 
        :post="post"
        :public-mode="true"
        @click:like="redirectToLogin"
        @click:tips="redirectToLogin"
        @click:reply="redirectToLogin"
        @click:rate="redirectToLogin"
        @click:menu="redirectToLogin"
      />
      <div class="download-widget" v-if="isWeb">
        <h3 class="download-title">Download the App</h3>
        <div class="store-links">
          <a href='https://apps.apple.com/gb/app/6buoys/id6686394530?uo=2' target="_blank">
            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83" 
                alt="Download on the App Store" class="store-badge" />
          </a>
          <a href="https://play.google.com/store" target="_blank">
            <img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg" 
                alt="Get it on Google Play" class="store-badge" />
          </a>
        </div>
      </div>
      <button @click="goToLogin" class="login-button">Login</button>
    </template>
  </div>
  <FooterPublic v-if="!loading" />
</template>

<script>
import axios from 'axios';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import PostCard from '@/components/PostCard.vue';
import FooterPublic from "@/components/FooterPublic.vue";
import { Capacitor } from '@capacitor/core';

export default {
  name: 'PublicHome',
  components: {
    LoadingSpinner,
    PostCard,
    FooterPublic
  },
  data() {
    return {
      posts: [],
      loading: true,
      isWeb: false,
    };
  },
  created() {
    this.fetchPosts();
    const platform = Capacitor.getPlatform();
    this.isWeb = platform === 'web';
  },
  methods: {
    async fetchPosts() {
      try {
        const response = await axios.get('https://api.6buoys.com/api/post-list/?limit=3');
        this.posts = response.data.map(post => ({
          ...post,
          showTips: false,
          tips: [],
          author: post.author || { avatar: 'default-avatar.png', first_name: 'Unknown' }
        }));
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        this.loading = false;
      }
    },
    redirectToLogin() {
      this.$router.push({ name: 'Login' });
    },
    goToLogin() {
      this.$router.push('/login');
    },
  }
};
</script>

<style scoped>
body {
  margin: 0;
  background-color: #15202b;
  color: #d9d9d9;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.timeline {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #15202b;
}

@media screen and (max-width: 600px) {
  .timeline {
    padding: 20px 4px;
  }
}

.post {
  border-radius: 16px;
  margin-bottom: 20px;
  background-color: #192734;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.post:hover {
  background-color: #22303c;
}

.post-header {
  display: flex;
  align-items: left;
  text-align: left;
  padding: 12px 16px;
}

.profile-pic {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
  border: 2px solid #1da1f2;
}

.post-details {
  flex-grow: 1;
}

.author {
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  font-size: 1em;
}

.post-date {
  font-size: 0.85em;
  color: #8899a6;
  margin: 0;
}

.post-info {
  display: flex;
  gap: 8px;
  align-items: center;
}

.info-badge {
  display: inline-block;
  color: #ffffff;
  padding: 4px 10px;
  border: #38444d 1px solid;
  border-radius: 20px;
  font-size: 0.85em;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
}

.post-content {
  padding: 12px 16px;
}

@media screen and (max-width: 600px) {
  .post-content {
    padding: 10px;
  }
}

.post-image {
  width: 100%;
  border-radius: 16px;
  max-height: 400px;
  object-fit: cover;
  margin-bottom: 12px;
}

.post-video {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 12px;
}

.post-text {
  font-size: 1.1em;
  color: #e1e8ed;
  margin: 0;
}

.post-actions {
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  color: #8899a6;
  font-size: 1;
}

.menu-container {
  position: relative;
  display: inline-block;
}

.three-dots {
  cursor: pointer;
  font-size: 18px;
  color: #8899a6; /* Match the color with the rest of the theme */
}

.dropdown-menu {
  display: block; /* Ensure the dropdown shows up */
  position: absolute;
  right: 0;
  background-color: #192734; /* Match the dark background of the app */
  border: 1px solid #38444d; /* Border style to match the app theme */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Slight shadow for depth */
  z-index: 10;
  width: 160px; /* Increase the width for more space */
  border-radius: 8px; /* Round the corners */
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  padding: 10px 15px; /* Adjust padding for better spacing */
  cursor: pointer;
  color: #e1e8ed; /* Light text color for readability */
}

.dropdown-menu ul li:hover {
  background-color: #22303c; /* Slightly lighter dark color on hover */
}

.tips-section {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
}

.tips-section:hover {
  color: #8899a6;
}

.likes-section {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: white;
}

.likes-section i {
  transition: color 0.2s;
}

.likes-section i.liked {
  color: #e0245e;
}

.likes-section i.not-liked {
  color: white;
  border: none;
}

.likes-section i:hover {
  color: #ff5a78;
  transform: scale(1.2);
}

.tips-list {
  padding: 10px 16px;
  background-color: #15202b;
  border-top: 1px solid #38444d;
}

.tip {
  padding: 12px;
  border-bottom: 1px solid #38444d;
  background-color: #192734;
  border-radius: 8px;
  margin-bottom: 10px;
}

.tip-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.tip-profile-pic {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.tip-author {
  font-weight: bold;
  color: #ffffff;
  margin: 0;
}

.tip-date {
  font-size: 0.8em;
  color: #8899a6;
  margin-left: auto;
}

.tip-body {
  color: #e1e8ed;
}

.tip-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.tip-rate {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #8899a6;
}

.tip-rate i {
  cursor: pointer;
  transition: color 0.2s;
}

.tip-rate .voted-up {
  color: #1da1f2;
}

.tip-rate .voted-down {
  color: #e0245e;
}

.tip-replies {
  font-size: 0.9em;
  color: #8899a6;
  cursor: pointer;
  transition: color 0.2s;
}

.tip-replies:hover {
  color: #1da1f2;
}

.new-tip-form {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.new-tip-form input {
  flex-grow: 1;
  padding: 8px 12px;
  border-radius: 20px;
  border: 1px solid #38444d;
  background-color: #192734;
  color: #e1e8ed;
  margin-right: 10px;
}

.new-tip-form input::placeholder {
  color: #8899a6;
}

.new-tip-form button {
  padding: 8px 16px;
  background-color: #1da1f2;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.new-tip-form button:hover {
  background-color: #0c8ddb;
}

.replies-list {
  margin-top: 10px;
  padding-left: 20px;
  border-left: 2px solid #38444d;
}

.reply {
  padding: 8px;
  border-bottom: 1px solid #38444d;
  background-color: #15202b;
  border-radius: 8px;
  margin-bottom: 8px;
}

.reply-header {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.reply-profile-pic {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
}

.reply-author {
  font-weight: bold;
  color: #ffffff;
  margin: 0;
}

.reply-date {
  font-size: 0.8em;
  color: #8899a6;
  margin-left: auto;
}

.new-reply-form {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.new-reply-form input {
  flex-grow: 1;
  padding: 6px 10px;
  border-radius: 15px;
  border: 1px solid #38444d;
  background-color: #192734;
  color: #e1e8ed;
  margin-right: 8px;
}

.new-reply-form button {
  padding: 6px 12px;
  background-color: #1da1f2;
  color: #ffffff;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.new-reply-form button:hover {
  background-color: #0c8ddb;
}

.voted-up {
  color: #1da1f2;
}

.voted-down {
  color: #e0245e;
}

.video-container {
  position: relative;
  width: 100%;
}

.fullscreen-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  z-index: 10;
}

.fullscreen-icon:hover {
  color: #1da1f2;
}

.post-video {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 12px;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.8);
  pointer-events: none;
}

.play-button-overlay i {
  pointer-events: none;
}

.login-button {
  padding: 8px 16px;
  background-color: #1da1f2;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* app store widget styling */
.download-widget {
  text-align: center;
  margin: 20px 0;
  padding: 20px;
  background-color: #192734;
  border-radius: 16px;
}

.download-title {
  font-size: 1.5em;
  color: #ffffff;
  margin-bottom: 15px;
}

.store-links {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
}

.store-badge {
  height: 50px;
  transition: transform 0.2s ease;
  cursor: pointer;
}

.store-badge:hover {
  transform: scale(1.1);
}

/* Add this new style for the footer component */
:deep(.footer-public) {
  width: 100%;
  max-width: none;
  margin: 0;
  padding: 20px;
}
</style>
  